import React from 'react';
// import { useEffect } from "react";
import Logo from '../Images/logo-GYNTUBOS.svg';
import Distri from '../Images/distribuicao-gyntubos-insdustriais.avif';
import Distrimob from '../Images/distribuicao-gyntubos-mobile.avif';
import Logos from '../Images/logos-valvulas-gyntubos-insdustriais.avif';
import borboleta from '../Images/valvulas/valvula-borboleta.avif';
import esfera from '../Images/valvulas/valvula-esfera.avif';
import gaveta from '../Images/valvulas/valvula-gaveta.avif';
import globo from '../Images/valvulas/valvula-globo.avif';
import retencao from '../Images/valvulas/valvula-retencao.avif';
import seguranca from '../Images/valvulas/valvula-seguranca.avif';
import carbono from '../Images/conexao/conexao-aco-carbono-gyntubos.avif';
import inox from '../Images/conexao/conexao-aco-inox-gyntubos.avif';
import tes from '../Images/conexao/conexao-grooved-mecanico-gyntubos.avif';
import reducao from '../Images/conexao/conexao-grooved-reducao.avif';
import tampao from '../Images/conexao/conexao-grooved-tampoes-gyntubos.avif';
import mecanico from '../Images/conexao/conexao-grooved-tes-gyntubos.avif';
import acoplamento from '../Images/conexao/conexao-groover-acoplamento-gyntubos.avif';
import curva from '../Images/conexao/conexao-grooved-curva-gyntubos.avif';
import tubocarbo from '../Images/tubos/tubo-aco-carbono-gyntubos.avif';
import galvanizado from '../Images/tubos/tubo-aco-galvanizado-gyntubos.avif';
import tuboinox from '../Images/tubos/tubo-aco-inox-gyntubos.avif';
import tubogrooved from '../Images/tubos/tubo-ranhurado-gyntubos.avif';
import cabine from '../Images/fire/cabine-incendio-gyntubos.avif';
import adaptadores from '../Images/fire/adaptadores.avif';
import esguicho from '../Images/fire/esguicho-gyntubos.avif';
import valvulafire from '../Images/fire/valvula-incendio-gyntubos.avif';
import carretel from '../Images/fire/carretel-gyntubos.avif';
import chave from '../Images/fire/chave-storz-gyntubos.avif';
import mangueira from '../Images/fire/mangueira.avif';
import bicos from '../Images/fire/bicos-sprinkler-gyntubos.avif';
import instrumentacao from '../Images/bg-instrumentacao-gyntubos.avif';
import instrumentacaomobile from '../Images/bg-instrumentacao-gyntubos-mobile.avif';
import logoauto from '../Images/logo-auto-gyntubos.avif';
import bgauto from '../Images/bg-automacao-gyntubos.avif';
import bgautomobile from '../Images/bg-automacao-gyntubos-mobile.avif';
import equipe from '../Images/equipe-vendas-gyntubos.avif';
import emborrachada from '../Images/valvulas/valvula-gaveta-cunho-borravcha-gyntubos.avif';
import { Helmet } from 'react-helmet';
import BtnOrc from '../Modules/BudgetBtn';
import BtnOrcSales from '../Modules/BudgetBtnSales';
import BtnOrcContato from '../Modules/BudgetBtnContato';

function Home(){
    return(
        <div>
            <Helmet>
                <title>Gyntubos Industriais | Tubos, conexões, válvulas e instrumentação | Americana-SP</title>
                <meta name="description" content="Há mais de 20 anos no mercado industrial fornecendo tubos, conexões, válvulas e instrumentação."/>
                <meta name="keywords" content="Válvulas, Conexões, Tubos, Instrumentação"/>
                <meta name="author" content="Lucas Reis"/>
            </Helmet>
            <section className='container-fluid bg-home mx-auto mt-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-8 text-center mt-5'>
                        <img className='p-0 p-md-5 w-75 my-5 py-5' src={Logo} alt="Logo Gyntubos Insdustriais" title="Logo Gyntubos Insdustriais"/>
                        <br/>
                        <p className='text-white text-center fs-2'>
                        Há mais de 20 anos no mercado industrial fornecendo tubos, conexões, válvulas e instrumentação.
                        </p>
                        < BtnOrc/>
                    </div>
                    <div className='col-12 mb-5'>

                    </div>
                </div>
            </section>

            <section className='container-fluid my-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 className='text-dark-red fw-bold display-3'>
                                Distribuidor autorizado
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 p-0'>
                        <img loading='lazy' className='img-fluid desk' src={Distri} alt='Distribuição Gyntubos' title='Distribuição Gyntubos'/>
                        <img loading='lazy' className='img-fluid mobile mx-auto' src={Distrimob} alt='Distribuição Gyntubos' title='Distribuição Gyntubos'/>
                    </div>
                    <div className='col-12 col-md-10 text-center my-5'>
                        <p className='text-grey fs-3'>
                        Localizada em Americana-SP, a <b>GYNTUBOS</b> fornece suas mercadorias para todo o Estado de São Paulo e para algumas regiões do Brasil. Contando com um completo estoque de materiais atendemos nossos clientes com qualidade e agilidade.
                        </p>
                    </div>
                </div>
            </section>
            <div style={{position:'relative', top:'-90px'}} id='valvula'></div>
            <section className='container-fluid mb-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 className='text-dark-red fw-bold display-3 mb-4'>
                            Válvulas Gaveta, Globo, Esfera , Borboleta, Retenção e Segurança.
                            </h1>
                        </div>
                        <div className='col-10 col-md-8 col-lg-6 col-xl-4'>                       
                            <img loading='lazy' className='img-fluid' src={Logos} alt='Válvulas Gaveta, Globo, Esfera , Borboleta, Retenção e Segurança.' title='Válvulas Gaveta, Globo, Esfera , Borboleta, Retenção e Segurança.'/>
                        </div>
                    </div>
                </div>
            </section>
            {/* VALVULAS */}
            <section className='container-fluid mb-5'>
                <div className='container'>
                    <div className='row justify-content-between gap-5'>
                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h4 className='text-dark-red fw-bold text-uppercase my-3'>
                                VÁLVULA GAVETA
                            </h4>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={gaveta} alt='VÁLVULA GAVETA' title='VÁLVULA GAVETA'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h4 className='text-dark-red fw-bold text-uppercase my-3'>
                                VÁLVULA GLOBO
                            </h4>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={globo} alt='VÁLVULA GLOBO' title='VÁLVULA GLOBO'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h4 className='text-dark-red fw-bold text-uppercase my-3'>
                                VÁLVULA ESFERA
                            </h4>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={esfera} alt='VÁLVULA ESFERA' title='VÁLVULA ESFERA'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h4 className='text-dark-red fw-bold text-uppercase my-3'>
                                VÁLVULA BORBOLETA
                            </h4>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={borboleta} alt='VÁLVULA BORBOLETA' title='VÁLVULA BORBOLETA'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h4 className='text-dark-red fw-bold text-uppercase my-3'>
                                VÁLVULA SEGURANÇA
                            </h4>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={seguranca} alt='VÁLVULA SEGURANÇA' title='VÁLVULA SEGURANÇA'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h4 className='text-dark-red fw-bold text-uppercase my-3'>
                                VÁLVULA RETENÇÃO
                            </h4>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={retencao} alt='VÁLVULA RETENÇÃO' title='VÁLVULA RETENÇÃO'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h4 className='text-dark-red fw-bold text-uppercase mt-3 mb-0'>
                                VÁLVULA GAVETA
                            </h4>
                            <p className='text-dark-red text-uppercase'>
                                com cunha emborrachada
                            </p>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={emborrachada} alt='VÁLVULA GAVETA COM CUNHA EMBORRACHADA' title='VÁLVULA GAVETA COM CUNHA EMBORRACHADA'/>
                        </div>

                    </div>
                </div>
            </section>
            {/* VALVULAS */}
            <section className='container-fluid bg-green-gyn mt-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 p-0 text-center'>
                        < BtnOrc/>
                    </div>
                </div>
            </section>
            <div style={{position:'relative', top:'-60px'}}  id='conexao'></div>
            <section className='container-fluid mt-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 className='text-dark-red fw-bold display-3'>
                                Conexões Aço Carbono e Inox
                            </h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container-fluid'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-9 col-md-8 col-lg-10 col-xl-8 text-start'>
                           <p className='text-grey fs-5 lh-lg'>
                            • SCH. 5, 10, 40 E 80<br></br>
                            • CLASSES 150 A 3000 LIBRAS<br></br>
                            • ROSCAS BSP E NPT<br></br>
                            • SOLDAS BW E SW
                           </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='container-fluid mb-5'>
                <div className='container'>
                    <div className='row justify-content-between text-center row-gap-4'>
                        <div className='col-12 col-md-6'>
                            <h4 className='text-dark-red fw-bold text-uppercase my-3'>
                                CONEXÕES DE CARBONO
                            </h4>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={carbono} alt='CONEXÕES DE CARBONO' title='CONEXÕES DE CARBONO'/>
                        </div>
                        <div className='col-12 col-md-6'>
                            <h4 className='text-dark-red fw-bold text-uppercase my-3'>
                                CONEXÕES DE INOX
                            </h4>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={inox} alt='CONEXÕES DE INOX' title='CONEXÕES DE INOX'/>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container-fluid bg-dark-red mt-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center'>
                        <h1 className='text-bege fw-bold display-3 my-5'>
                            Conexões Ranhuradas Grooved
                        </h1>
                    </div>
                </div>
            </section>

             {/* GROOVED */}
             <section className='container-fluid my-5'>
                <div className='container'>
                    <div className='row justify-content-between gap-5'>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h2 className='text-grey fw-bold my-3'>
                                Tes mecânico
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-grey border rounded-3' src={mecanico} alt='Tes mecânico' title='Tes mecânico'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h2 className='text-grey fw-bold my-3'>
                                Reduções 
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-grey border rounded-3' src={reducao} alt='Reduções ' title='Reduções '/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h2 className='text-grey fw-bold my-3'>
                                Tes
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-grey border rounded-3' src={tes} alt='Tes' title='Tes'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h2 className='text-grey fw-bold my-3'>
                                Curvas 90º
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-grey border rounded-3' src={curva} alt='Curvas 90º' title='Curvas 90º'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h2 className='text-grey fw-bold my-3'>
                                Acoplamentos
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-grey border rounded-3' src={acoplamento} alt='Acoplamentos' title='Acoplamentos'/>
                        </div>

                        <div className='col-12 col-md-4 col-lg-3 text-center'>
                            <h2 className='text-grey fw-bold my-3'>
                                Tampões
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-grey border rounded-3' src={tampao} alt='Tampões' title='Tampões'/>
                        </div>

                    </div>
                </div>
            </section>
            {/* GROOVED */}

            <section style={{height: "1.5rem"}} className='container-fluid bg-dark-red'>
                <div className='row justify-content-center'>
                </div>
            </section>
            <div style={{position:'relative', top:'-60px'}} id='tubos'></div>
            <section className='container-fluid mt-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center'>
                        <h1 className='text-dark-red fw-bold display-3'>
                            Tubos
                        </h1>
                        <p className='text-grey text-uppercase'>
                        AÇO CARBONO | AÇO INOX | AÇO GALVANIZADO | RANHURADO GROOVED
                        </p>
                    </div>
                </div>
            </section>
             {/* TUBOS */}
             <section className='container-fluid mb-5'>
                <div className='container mx-auto'>
                    <div className='row justify-content-center text-center'>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Aço carbono
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={tubocarbo} alt='Aço carbono' title='Aço carbono'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Aço inox
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={tuboinox} alt='Aço inox' title='Aço inox'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Galvanizado
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={galvanizado} alt='Aço Galvanizado' title='Aço Galvanizado'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Ranhurado
                            </h2>
                            <img loading='lazy' className='img-fluid border-2 border-dark-red border rounded-3' src={tubogrooved} alt='Ranhurado grooved' title='Ranhurado grooved'/>
                        </div>

                    </div>
                </div>
            </section>

            <section className='container-fluid bg-green-gyn mt-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 p-0 text-center'>
                        <BtnOrcSales />
                    </div>
                </div>
            </section>
            <div style={{position:'relative', top:'-60px'}} id='incendio'></div>
            <section className='container-fluid bg-red-fire'>
                <div className='row justify-content-center'>
                    <div className='col-12 p-0 text-center'>
                        <h1 className='text-white fw-bold display-3 my-5'>
                            Linha Industrial de Incêndio
                        </h1>
                    </div>
                </div>
            </section>

            <section className='container-fluid bg-light-grey'>
                <div className='container mx-auto'>
                    <div className='row justify-content-center text-center pt-3 pb-5'>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Abrigos
                            </h2>
                            <img loading='lazy' className='img-fluid' src={cabine} alt='Abrigos' title='Abrigos'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Válvulas
                            </h2>
                            <img loading='lazy' className='img-fluid' src={valvulafire} alt='Válvulas' title='Válvulas'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Adaptadores Storz
                            </h2>
                            <img loading='lazy' className='img-fluid' src={adaptadores} alt='Adaptadores Storz' title='Adaptadores Storz'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Esguichos
                            </h2>
                            <img loading='lazy' className='img-fluid' src={esguicho} alt='Esguichos' title='Esguichos'/>
                        </div>

                    </div>
                </div>
            </section>

            <section style={{height: "1.5rem"}} className='container-fluid bg-red-fire'>
                <div className='row justify-content-center'>
                </div>
            </section>

            <section className='container-fluid bg-light-grey'>
                <div className='container mx-auto'>
                    <div className='row justify-content-center text-center pt-3 pb-5'>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Carretel Manual
                            </h2>
                            <img loading='lazy' className='img-fluid' src={carretel} alt='Carretel Manual' title='Carretel Manual'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Chave Storz
                            </h2>
                            <img loading='lazy' className='img-fluid' src={chave} alt='Chave Storz' title='Chave Storz'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Mangueiras
                            </h2>
                            <img loading='lazy' className='img-fluid' src={mangueira} alt='Mangueiras' title='Mangueiras'/>
                        </div>

                        <div className='col-12 col-md-6 col-lg-3'>
                            <h2 className='text-grey fw-bold my-3'>
                                Bicos Sprinkler
                            </h2>
                            <img loading='lazy' className='img-fluid' src={bicos} alt='Bicos Sprinkler' title='Bicos Sprinkler'/>
                        </div>

                    </div>
                </div>
            </section>

            <section style={{height: "8rem"}} className='container-fluid bg-red-fire'>
                <div className='row justify-content-center'>
                </div>
            </section>
            <div style={{position:'relative', top:'-60px'}} id='instrumentacao'></div>
            <section className='container-fluid bg-light-grey py-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center'>
                        <h1 className='text-dark-red fw-bold display-3'>
                            Instrumentação
                        </h1>
                        <p className='text-grey text-uppercase'>
                            MANÔMETROS | TERMÔMETROS | SELOS DIAFRAGMA SIFÕES TIPO U | SIFÕES TROMBETA 
                        </p>
                    </div>
                    <div className='col-12'>
                        <img loading='lazy' className='img-fluid desk' src={instrumentacao} alt='Instrumentação Gyntubos' title='Instrumentação Gyntubos'/>
                        <img loading='lazy' className='img-fluid mobile mx-auto' src={instrumentacaomobile} alt='Instrumentação Gyntubos' title='Instrumentação Gyntubos'/>
                    </div>
                </div>
            </section>

            <section style={{height: "4rem"}} className='container-fluid bg-red-fire'>
                <div className='row justify-content-center'>
                </div>
            </section>
            <div style={{position:'relative', top:'-60px'}} id='automacao'></div>
            <section className='container-fluid bg-light-grey py-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center'>
                        <h1 className='text-dark-red fw-bold display-3'>
                            Automação e Pneumática
                        </h1>
                    </div>
                    <div className='col-10 col-md-8 col-lg-6 col-xl-4'>                       
                        <img loading='lazy' className='img-fluid' src={logoauto} alt='Automação e Pneumática' title='Automação e Pneumática'/>
                    </div>
                    <div className='col-12'>
                        <img loading='lazy' className='img-fluid desk' src={bgauto} alt='Automação e Pneumática' title='Automação e Pneumática'/>
                        <img loading='lazy' className='img-fluid mobile mx-auto' src={bgautomobile} alt='Automação e Pneumática' title='Automação e Pneumática'/>
                    </div>
                </div>
            </section>
            <div style={{position:'relative', top:'-60px'}} id='contato'></div>
            <section className='container-fluid'>
                <div className='container mx-auto'>
                    <div className='row justify-content-center align-items-center py-5 py-lg-0'>

                        <div className='col-12 col-lg-8 order-2 order-lg-1'>
                            <p className='text-grey lh-lg mb-5 fs-5'>
                            Nossa empresa conta com uma equipe de vendas altamente qualificada, visando atender as diversas áreas do setor industrial, trabalhando sempre com qualidade e excelência.
                            </p>
                            <BtnOrcContato />
                        </div>

                        <div className='col-12 col-lg-4 order-1 order-lg-2 mb-5 mb-lg-0'>
                            <img loading='lazy' className='img-fluid border-5 border-light-red border-start border-end' src={equipe} alt='Equipe vendas Gyntubos' title='Equipe vendas Gyntubos'/>
                        </div>

                    </div>
                </div>
            </section>
            <section style={{height: "4rem"}} className='container-fluid bg-light-red'>
                <div className='row justify-content-center'>
                </div>
            </section>
            <section className='container-fluid bg-light-grey py-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center'>
                        <img className='w-25 mb-5' src={Logo} alt="Logo Gyntubos Insdustriais" title="Logo Gyntubos Insdustriais"/>
                        <br/>
                        <p className='fs-3 fw-bold m-0'>
                            <a className='text-dark-red text-decoration-none' href='tel:+551934659060' target='_self'>(19) 3465-9060</a>
                        </p>
                        <p className='fs-3 fw-bold m-0'>
                            <a className='text-dark-red text-decoration-none' href='mailto:vendas@gyntubos.com' target='_self'>vendas@gyntubos.com</a> 
                        </p>
                    </div>
                </div>
            </section>

            <footer className='container-fluid bg-red-fire py-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center'>
                        <p className='text-white lh-lg'>
                            Copyright © 2025 <b>GYNTUBOS INDUSTRIAIS</b> - Todos os direitos reservados
                        </p>
                        <p className='text-white lh-lg'>
                            Av. Henrique R. G. A. Brechmacher, 2105, Jd. Brasil, Americana-SP – 13474-140 – Rodovia Anhanguera Km-125,5
                        </p>
                    </div>
                </div>
            </footer>

        </div>
    );
};

export default Home;