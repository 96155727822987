import { Button } from 'react-bootstrap';
import { TbBrandWhatsapp } from "react-icons/tb";

const handleConversion = () => {
const url = 'https://wa.me/5519981389451?text=Ol%C3%A1%2C%20quero%20solicitar%20um%20or%C3%A7amento.';

    if (typeof window.gtag_report_conversion === 'function') {
        window.gtag_report_conversion(url);
    } else {
        // fallback caso a função ainda não tenha sido carregada
        window.location.href = url;
    }
    };

    const BotaoContato = () => {
    return (
         <Button onClick={handleConversion} className='px-5 btn-lg text-white mt-5 fs-4' variant="light-red">
            <span className='me-2'><TbBrandWhatsapp /></span>
            Pedir orçamento
        </Button>
    );
    };

export default BotaoContato;
